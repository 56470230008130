import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { WiiBoardService } from '../../services/wiiboard.service';
import { WiiBoardState } from '../../models/wiiboard-state.model';
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-information-connexion',
  templateUrl: './information-connexion.component.html',
})
/**
 * This component representes the informative div of the connexion with wiiBoard
 */
export class InformationConnexionComponent {
  public readonly wiiConnected: Observable<boolean>;
  public readonly wiiState: Observable<WiiBoardState>;

  constructor(wiiboardService: WiiBoardService) {
    this.wiiState = wiiboardService.wiiState.pipe(tap((v) => console.log('WIIST', v)));
    this.wiiConnected = this.wiiState.pipe(map((state) => state && state.wiiBoardConnected));
  }
}
