<div class="connexion-global">
  <ng-container *ngIf="wiiConnected | async">
    <p class="connected">
      <span>{{ 'app.weight.boardconnected' | translate }}</span>
    </p>
    <div *ngIf="wiiState | async as state">
      <ng-container *ngIf="state.niveauBatterie !== null">{{
        'board.batterielevel' | translate: { level: state.niveauBatterie | number: '1.0-0' }
      }}</ng-container>
    </div>
  </ng-container>

  <!-- Test with false because it start with null -->
  <p class="unconnected" *ngIf="(wiiConnected | async) === false">
    <span>{{ 'app.weight.boardnotconnected' | translate }}</span>
  </p>
</div>
